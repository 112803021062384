/* @generated */
// prettier-ignore
if (Intl.PluralRules && typeof Intl.PluralRules.__addLocaleData === 'function') {
  Intl.PluralRules.__addLocaleData({"data":{"categories":{"cardinal":["one","two","many","other"],"ordinal":["other"]},"fn":function(n, ord) {
  var s = String(n).split('.'), i = s[0], v0 = !s[1], t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1);
  if (ord) return 'other';
  return n == 1 && v0 ? 'one'
    : i == 2 && v0 ? 'two'
    : v0 && (n < 0 || n > 10) && t0 && n10 == 0 ? 'many'
    : 'other';
}},"locale":"he"})
}
